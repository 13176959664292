import axios from 'axios';

const API_BASE_URL = 'https://thoughtsweb20241101194435.azurewebsites.net/api';

export const fetchCategories = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/Category`);
    return response.data;
  } catch (error) {
    console.error('Error fetching categories:', error);
    throw error;
  }
};

export const analyzeAndSaveMessage = async (content, userId) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/Message/analyze`, {
      content,
      userId
    });
    return response.data;
  } catch (error) {
    console.error('Error analyzing and saving message:', error);
    throw error;
  }
};

export const createMessage = async (content, categoryId) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/Message/createMessage`, {
      content,
      categoryId
    });
    return response.data;
  } catch (error) {
    console.error('Error creating message:', error);
    throw error;
  }
};

export const createCategory = async (newCategoryName, userId) => {
  try {
    const requestBody = {
      newCategoryName,
      userId
    };
    const response = await axios.post(`${API_BASE_URL}/Category/createCategory`, requestBody);
    return response.data;
  } catch (error) {
    console.error('Error creating category:', error);
    throw error;
  }
};

export const updateCategory = async (id, updatedCategoryName) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/Category/updateCategory`, {
      id,
      updatedCategoryName
    });
    return response.data;
  } catch (error) {
    console.error('Error updating category:', error);
    throw error;
  }
};

export const deleteCategory = async (id) => {
  try {
    await axios.delete(`${API_BASE_URL}/Category/${id}`);
  } catch (error) {
    console.error('Error deleting category:', error);
    throw error;
  }
};

export const updateMessage = async (id, updatedMessage) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/Message/${id}`, updatedMessage);
    return response.data;
  } catch (error) {
    console.error('Error updating message:', error);
    throw error;
  }
};

export const deleteMessage = async (id) => {
  try {
    await axios.delete(`${API_BASE_URL}/Message/${id}`);
  } catch (error) {
    console.error('Error deleting message:', error);
    throw error;
  }
};

export const fetchMessages = async (userId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/Message?userId=${userId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching messages:', error);
    throw error;
  }
};
